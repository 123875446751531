import {CountUp} from "countup.js";

import { get, add, list, update, total, exists } from 'cart-localstorage'

let Cart = Garnish.Base.extend({
    cart: null,
    counter: null,

    $cartTriggerBtn: null,
    $amountContainer: null,
    totalAmount: null,

    init() {

        this.initCart()

        this.$cartTriggerBtn = $('.cart-trigger')
        this.$amountContainer = $('#dollar-amount-cart')
        this.totalAmount = 0

        this.intCartAmount()
    },

    initCart() {
        this.cart = list()
    },

    intCartAmount() {
        this.counter = new CountUp('dollar-amount-cart', total(), {
            startVal: 0,
            duration: 1,
            decimalPlaces: 2,
            useEasing: false,
        })

        this.counter.start()
    },

    updateCartTotalAmount() {
        this.counter.update(total())
    },

    addItemToCart(data) {
        if (exists(data.id)) {
            let lineItem = get(data.id)
            let newPrice = lineItem.price + data.price

            update(data.id, 'price', newPrice)
        } else {
            add(data)
        }
    },

})


Garnish.$doc.ready(function () {
    GG.Cart = new Cart()
})